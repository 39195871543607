/**
 * Created by joerg on 5/3/17.
 */

// angular
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

export class mn_animations {
    static configure():any {
        return [
            BrowserAnimationsModule,
        ];
    }
}